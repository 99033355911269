<template>
  <section class="main-wrap">
    <header class="inner-page-header">
      <h1 class="big-title">Create your Google ads preview</h1>
    </header>

    <section class="steps-wrap">
      <div class="step">
        <p class="step-number">1</p>
        <p class="step-name">Download our template</p>
        <p class="step-description">Download our Google ads<br />structure template</p>
        <br />
        <a href="https://docs.google.com/spreadsheets/d/1P8-rIsk1Ecrs2yZKoQVG-ISwhKGzyyh2D7MiBEyXVc8/copy" target="_blank">Download</a>
        <!-- <a href="https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/showadz-structure-google-adz.xlsx?alt=media&token=7f847c7a-47eb-418d-80b3-597390b4576d" download>Microsoft Excel (.xlsx)</a> -->
      </div>
      <div class="step">
        <p class="step-number">2</p>
        <p class="step-name">Write your ads</p>
        <p class="step-description">
          Complete our template with your campaigns name, ad groups, keywords, headlines, sitelinks, callouts, call extension and Structured Snippets
        </p>
      </div>
      <div class="step">
        <p class="step-number">3</p>
        <p class="step-name">Upload and preview</p>
        <p class="step-description">Upload your .xlsx document and get a Google ads preview quickly</p>
        <div class="input-wrap">
          <input
            id="import"
            type="file"
            name="import"
            class="inputfile"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            required
            @change="uploadXlxs($event)"
          />
        </div>
      </div>
    </section>

    <button
      type="submit"
      class="bt"
      :class="{ 'is-loading': isSaving, 'is-importation': importationState }"
      :disabled="!importedFile"
      @click.prevent="importXlsx"
    >
      <div class="bt-text">
        <p>Generate your preview</p>
        <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
      </div>
      <p class="loader">
        &nbsp;
        <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
          <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
            <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
            </path>
          </g>
        </svg>
        &nbsp;
      </p>
    </button>

    <div v-if="importationState" class="progress-wrap">
      <p class="pourcentage">{{ importProgress }}%</p>
      <div class="progress-bar-wrap">
        <div class="progress-bar" :style="'width:' + importProgress + '%'"></div>
      </div>
    </div>

    <div v-if="error" class="error-wrap">
      <p class="fb-log-error warning">
        <img data-v-704f54dc="" src="/img/error-alert.ec494c8f.svg" alt="alert">
        <strong>
          Some configurations seems to be conflicting with the Google Import process.
          <br><br> Import returned : <br>
        </strong>
        {{ error.message }} <br>
        <strong>at</strong> <br>
        <span v-if="importProgress === 31">campaigns</span>
        <span v-if="importProgress === 31">campaigns-keywords</span>
        <span v-if="importProgress === 47">campaigns-annonces</span>
        <span v-if="importProgress === 59">campaigns-sitelinks</span>
        <span v-if="importProgress === 75">campaigns-callouts</span>
        <span v-if="importProgress === 86">campaigns-extensions</span>
        <span v-if="importProgress === 90">campaigns-structuredsnippets</span>
        <br><br>
        Please verify this section of your Google file and try again.
      </p>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'
import { functions, storageRef, firebase } from '@/firebase/init'
// import AccountClientCampaignGoogleAdzDB from '@/firebase/account-client-campaign-googleAdz-db'

export default {
  head: function () {
    return {
      title: {
        inner: 'Google',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} article details page`,
          id: 'desc',
        },
      ],
    }
  },
  data() {
    return {
      clientId: null,
      campaignId: null,
      isSaving: false,
      fileProgress: 0,
      importProgress: 0,
      importedFile: null,
      importationState: 0,
      error: '',
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['appTitle', 'appDomain', 'platforms']),
    ...mapState('users', ['account', 'selectedClient', 'selectedCampaign']),
  },
  watch: {
    account: {
      async handler(account) {
        if (!isNil(account)) {
          this.clientId = this.$route.params.clientId
          this.campaignId = this.$route.params.campaignId

          this.selectClient(this.$route.params.clientId)
          this.selectCampaign({
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
          })
        }
      },
      immediate: true,
    },
    $route: {
      async handler($route) {
        if ($route && $route.params) {
          this.clientId = $route.params.clientId
          this.campaignId = $route.params.campaignId
        }
      },
      immediate: true,
    },
  },
  methods: {
    uploadXlxs(event) {
      // console.log('Bingo', event)
      const fileName = `import-googleAdz-data-${this.campaignId}.xlsx`

      this.isSaving = true

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      const uploadTask = storageRef.child(fileName).put(event.target.files[0])
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.fileProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // console.log('Upload is ' + this.fileProgress + '% done')
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              // console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              // console.log('Upload is running')
              break
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          // console.log(error)
          this.isSaving = false
          this.error = error
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            // console.log('File available at', downloadURL)
            this.importedFile = downloadURL
            this.isSaving = false
          })
        }
      )
    },
    async importXlsx() {
      const duplicateAndArchive = functions.httpsCallable('duplicateAndArchive')
      const cleanUpBeforeImport = functions.httpsCallable('cleanUpBeforeImport')
      const importFromSheet = functions.httpsCallable('importFromSheet')
      // const deleteSheetAfterImport = functions.httpsCallable('deleteSheetAfterImport')

      this.importationState = 1
      this.isSaving = true
      this.importProgress = 1

      const credentials = {
        accountId: this.account[0].id,
        clientId: this.clientId,
        campaignId: this.campaignId,
      }

      // console.log('Will Duplicate and keep history before Import ... Loading ...')

      try {
        // This may throw an error
        await duplicateAndArchive(credentials)
        // console.log('duplicateAndArchive return - Loading finished')
        this.importProgress = 1

        // console.log('Will Cleanup before Import ... Loading ...')

        await cleanUpBeforeImport(credentials)
        // console.log('cleanUpBeforeImport return - Loading finished')
        this.importProgress = 2

        // console.log('Will Campaigns importFromSheet ... Loading ...')

        const res1 = await importFromSheet({ ...credentials, filename: this.importedFile, importType: 'campaigns' })
        // console.log('Campaigns importFromSheet return - Loading finished')
        this.importProgress = 31

        if (res1.data.error || !res1.data.success) {
          this.error = res1.data.error
          return
        }

        // console.log('Will Keywords importFromSheet ... Loading ...')

        const res2 = await importFromSheet({ ...credentials, filename: this.importedFile, importType: 'campaigns-keywords' })
        // console.log('Keywords importFromSheet return - Loading finished')
        this.importProgress = 47

        if (res2.data.error || !res2.data.success) {
          this.error = res2.data.error
          return
        }

        // console.log('Will Annonces importFromSheet ... Loading ...')

        const res3 = await importFromSheet({ ...credentials, filename: this.importedFile, importType: 'campaigns-annonces' })
        // console.log('Annonces importFromSheet return - Loading finished')
        this.importProgress = 59

        if (res3.data.error || !res3.data.success) {
          this.error = res3.data.error
          return
        }

        // console.log('Will sitelinks importFromSheet ... Loading ...')

        const res4 = await importFromSheet({ ...credentials, filename: this.importedFile, importType: 'campaigns-sitelinks' })
        // console.log('sitelinks importFromSheet return - Loading finished')
        this.importProgress = 75

        if (res4.data.error || !res4.data.success) {
          this.error = res4.data.error
          return
        }

        // console.log('Will callouts importFromSheet ... Loading ...')

        const res5 = await importFromSheet({ ...credentials, filename: this.importedFile, importType: 'campaigns-callouts' })
        // console.log('callouts importFromSheet return - Loading finished')
        this.importProgress = 86

        if (res5.data.error || !res5.data.success) {
          this.error = res5.data.error
          return
        }

        // console.log('Will extensions importFromSheet ... Loading ...')

        const res6 = await importFromSheet({ ...credentials, filename: this.importedFile, importType: 'campaigns-extensions' })
        // console.log('extensions importFromSheet return - Loading finished')
        this.importProgress = 90

        if (res6.data.error || !res6.data.success) {
          this.error = res6.data.error
          return
        }

        // console.log('Will structuredsnippets importFromSheet ... Loading ...')
        const res7 = await importFromSheet({ ...credentials, filename: this.importedFile, importType: 'campaigns-structuredsnippets' })
        // console.log('structuredsnippets importFromSheet return - Loading finished')
        this.importProgress = 98

        if (res7.data.error || !res7.data.success) {
          this.error = res7.data.error
          return
        }

        this.importationState = 'end'
        this.importProgress = 100
        this.isSaving = false

        this.$router.push({ name: 'google', params: { clientId: this.clientId, campaignId: this.campaignId } })
        // console.log('Will deleteSheetAfterImport ... Loading ...')
        // await deleteSheetAfterImport(credentials)
        // console.log('Delete sheet after import')
      } catch(e) {
        this.error = e
      }
    },
    ...mapActions('users', ['selectClient', 'selectCampaign']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';

.main-wrap {
  text-align: center;
}

.bt {
  &.is-importation {
    display: none;
  }
}

.progress-wrap {
  width: 350px;
  margin: 0 auto;
  margin-top: 25px;

  .pourcentage {
    font-size: 24px;
    font-weight: 300;
    color: $dark-grey;
    margin-bottom: 10px;
  }

  .progress-bar-wrap {
    width: 100%;
    height: 10px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;

    .progress-bar {
      transition: all 0.4s ease;
      height: 10px;
      background: linear-gradient(to right, #2e7df2, #8a23f1);
    }
  }
}

.inner-page-header {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 5vw;
}

.steps-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .step {
    position: relative;
    text-align: center;
    width: calc(33.33% - 15px);
    background-color: #fff;
    border: 1px solid #e0e2e6;
    border-radius: 3px;
    padding: 50px 40px;

    a {
      font-weight: 600;
      color: $purple-blue;
      font-size: 14px;
      width: 100%;
      display: block;
      margin-top: 30px;

      &:last-child {
        margin-top: 10px;
      }
    }
  }

  .step-number {
    position: absolute;
    left: 50%;
    top: -25px;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: linear-gradient(to right, #2e7df2, #8a23f1);
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
  }

  .step-name {
    font-size: 20px;
    font-weight: 400;
    color: $dark-grey;
    margin-bottom: 20px;
  }

  .step-description {
    font-size: 14px;
    line-height: 1.7;
    color: $dark-grey;
    font-weight: 600;
  }

  .input-wrap {
    width: 100%;
    background-color: $grey;
    margin-top: 20px;
    padding: 10px 10px 13px 10px;
    border-radius: 3px;
  }
}

.bt {
  position: relative;
  margin-top: 35px;
  display: inline-block;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.55;
  }
}

.fb-log-error {
  color: $dark-grey;
  width: 100%;
  text-align: center;

  img {
    display: inline-block;
    width: 50px;
    margin-bottom: 20px;
  }

  &.video {
    display: flex;
    margin-top: 20px;

    img {
      width: 40px;
      margin-bottom: 0;
    }

    p {
      text-align: left;
      padding-left: 20px;
      width: 70%;
      font-size: 14px;
    }
  }
}

.fb-log-error.warning {
  padding: 25px 10px;
  border: 1px solid #f9ba34;
  background: rgba(#fff, 0.45);
  font-size: 14px;
  margin-top: 25px;
  margin-bottom: -25px;
  text-align: center;
  border-radius: 4px;

  img {
    display: block;
    max-width: 45px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}
</style>
